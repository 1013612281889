import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import styled from "@emotion/styled";
const apiUrl = process.env.REACT_APP_BASE_URL;

const KeyStat = ({ ticker }) => {
  const [data, setData] = useState({});

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#17273B",
      color: "#A5CFF2",
    },
  }));

  useEffect(() => {
    // Fetch data from the Flask API
    axios
      .get(`${apiUrl}api/get_key_statistics?ticker=${ticker}`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [ticker]);

  return (
    <Container
      sx={{
        margin: "2rem 0",
        background: "linear-gradient(to right, #9ac1fa, #c5f5de)",
        padding: "1rem",
        paddingBottom: "2.5rem"
      }}
    >
      <h2
        style={{
          fontSize: "1.75rem",
          color: "#17273B",
          lineHeight: "1.2",
          fontWeight: "600",
        }}
      >
        {ticker} Key Statistics
      </h2>

      {/* Table 1 */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Paper>
            <TableContainer sx={{ maxHeight: 170 }}>
              <Table stickyHeader size="small">
                <TableHead sx={{ fontWeight: "bold" }}>
                  <TableRow>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", fontSize: "1rem" }}
                    >
                      Category
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", fontSize: "1rem" }}
                    >
                      Value
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Open</TableCell>
                    <TableCell>{data.open_price}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>High</TableCell>
                    <TableCell>{data.high_price}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Low</TableCell>
                    <TableCell>{data.low_price}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Bid</TableCell>
                    <TableCell>{data.bid_price}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Offer</TableCell>
                    <TableCell>{data.offer_price}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Previous close</TableCell>
                    <TableCell>{data.previous_close}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        {/* Table 2 */}
        <Grid item xs={12} sm={4}>
          <Paper>
            <TableContainer sx={{ maxHeight: 170 }}>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", fontSize: "1rem" }}
                    >
                      Category
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", fontSize: "1rem" }}
                    >
                      Value
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Average volume</TableCell>
                    <TableCell>{data.average_volume}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Shares outstanding</TableCell>
                    <TableCell>{data.shares_outstanding}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Free float</TableCell>
                    <TableCell>{data.free_float}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>P/E (TTM)</TableCell>
                    <TableCell>{data.pe_ratio}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Market cap</TableCell>
                    <TableCell>{data.market_cap}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>EPS (TTM)</TableCell>
                    <TableCell>{data.eps}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        {/* Table 3 */}
        <Grid item xs={12} sm={4}>
          <Paper>
            <TableContainer sx={{ maxHeight: 170 }}>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", fontSize: "1rem" }}
                    >
                      Category
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", fontSize: "1rem" }}
                    >
                      Value
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Annual div (ADY)</TableCell>
                    <TableCell>{data.annual_dividend}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Annual div yield (ADY)</TableCell>
                    <TableCell>{data.annual_dividend_yield}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Div ex-date</TableCell>
                    <TableCell>{data.dividend_ex_date}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Div pay-date</TableCell>
                    <TableCell>{data.dividend_pay_date}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default KeyStat;
