import "chart.js/auto";
import "chartjs-adapter-date-fns";
import React from "react";
import { Routes, Route } from "react-router-dom";

import Backtest from "./pages/Backtest";
import { LoginPage } from "./pages/Logintest";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Backtest />} />
      <Route path="/login" element={<LoginPage/>} />
    </Routes>
  );
};

export default App;
