import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
const apiUrl = process.env.REACT_APP_BASE_URL;

function MultiLevelDropdown({ onSelectionChange }) {
  const [data, setData] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedTicker, setSelectedTicker] = useState("");
  const [tickerList, setTickerList] = useState([]);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [catGeneral, setCatGeneral] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    // Fetch data from the API when the component mounts
    async function fetchData() {
      try {
        const response = await fetch(`${apiUrl}api/available_tickers`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        setData(jsonData);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    // Update the ticker list when the selected category changes
    if (selectedCategory && data && data.tickers[selectedCategory]) {
      setTickerList(Object.keys(data.tickers[selectedCategory]));
      setSelectedTicker("");
    }
  }, [selectedCategory, data]);

  // const handleCategoryChange = (e) => {

  const handleCategoryChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedCategory(selectedValue);
    console.log(selectedValue);
    // List of category values that should be categorized as "ETFS"
    const etfsCategories = [
      "Popular Stock ETFs",
      "Commodity ETFs",
      "Bond ETFs",
      "Real Estate ETFs",
      "Thematic and Sector ETFs",
    ];

    // Check if the selected value is in the "ETFS" categories
    if (etfsCategories.includes(selectedValue)) {
      setCatGeneral("ETFS");
      console.log("set efts");
    } else {
      setCatGeneral("STOCKS");
      console.log("set stocks");
    }

    setIsSubMenuOpen(true); // Open the submenu when a category is selected
  };

  // };

  const handleTickerChange = (e) => {
    setSelectedTicker(e.target.value);

    // Call the onSelectionChange prop to pass the selected values to the main app
    onSelectionChange(catGeneral, e.target.value);
  };

  return (
    <div>
      <FormControl
        fullWidth
        variant="outlined"
        style={{ marginBottom: "20px" }}
      >
        <InputLabel>Select Category</InputLabel>
        <Select
          value={selectedCategory}
          onChange={handleCategoryChange}
          label="Select Category"
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200, // Set the maximum height for the menu
                overflowY: "auto", // Enable vertical scrolling
              },
            },
          }}
        >
          {isLoading ? (
            <MenuItem sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }} disabled>
              <CircularProgress
                style={{ color: "#344559" }}
                value={25}
                thickness={6}
                // disableShrink = {true}
              />
              <p className="waiting_text">This may take 5-10 seconds depending on your internet speed</p>
            </MenuItem>
          ) : (
            data &&
            Object.keys(data.tickers).map((category) => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>

      {selectedCategory && (
        <FormControl
          style={{ marginBottom: "20px" }}
          fullWidth
          variant="outlined"
        >
          <InputLabel>Select Ticker</InputLabel>
          <Select
            value={selectedTicker}
            onChange={handleTickerChange}
            label="Select Ticker"
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200, // Set the maximum height for the menu
                  overflowY: "auto", // Enable vertical scrolling
                },
              },
            }}
          >
            {tickerList.map((tickerSymbol) => (
              <MenuItem
                key={`${selectedCategory}-${tickerSymbol}`}
                value={tickerSymbol}
              >
                {tickerSymbol}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {/* Second-level dropdown */}
      {isSubMenuOpen && (
        <div className="submenu">
          {/* Add your second-level menu items here */}
        </div>
      )}
    </div>
  );
}

export default MultiLevelDropdown;
// import React, { useState, useEffect } from "react";
// import "./MultiLevelDropdown.css"; // Import your CSS file for styling

// function MultiLevelDropdown({ onSelectionChange }) {
//   const [data, setData] = useState(null);
//   const [selectedCategory, setSelectedCategory] = useState("");
//   const [selectedTicker, setSelectedTicker] = useState("");
//   const [tickerList, setTickerList] = useState([]);

//   useEffect(() => {
//     // Fetch data from the API when the component mounts
//     async function fetchData() {
//       try {
//         const response = await fetch("http://127.0.0.1:8000/api/available_tickers");
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
//         const jsonData = await response.json();
//         setData(jsonData);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     }

//     fetchData();
//   }, []);

//   useEffect(() => {
//     // Update the ticker list when the selected category changes
//     if (selectedCategory && data && data.tickers[selectedCategory]) {
//       setTickerList(Object.keys(data.tickers[selectedCategory]));
//       setSelectedTicker("");
//     }
//   }, [selectedCategory, data]);

//   const handleCategoryChange = (e) => {
//     setSelectedCategory(e.target.value);
//     setSelectedTicker("");
//   };

//   const handleTickerChange = (e) => {
//     setSelectedTicker(e.target.value);
//     onSelectionChange(selectedCategory, e.target.value);
//   };

//   return (
//     <div className="dropdown-container">
//       <label className="label">Select Category and Ticker:</label>
//       <select
//         className="select"
//         name="category"
//         value={selectedCategory}
//         onChange={handleCategoryChange}
//       >
//         <option value="">Select Category</option>
//         {data &&
//           Object.keys(data.tickers).map((category) => (
//             <option key={category} value={category}>
//               {category}
//             </option>
//           ))}
//       </select>

//       {selectedCategory && (
//         <select
//           className="select"
//           name="ticker"
//           value={selectedTicker}
//           onChange={handleTickerChange}
//         >
//           <option value="">Select Ticker</option>
//           {tickerList.map((tickerSymbol) => (
//             <option
//               key={`${selectedCategory}-${tickerSymbol}`}
//               value={tickerSymbol}
//             >
//               {tickerSymbol}
//             </option>
//           ))}
//         </select>
//       )}
//     </div>
//   );
// }

// export default MultiLevelDropdown;
