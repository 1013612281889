import { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../firebase";

import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";

import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router";

const UserAuthContext = createContext();

function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
}

function isObject(object) {
  return object != null && typeof object === "object";
}

export const UserAuthContextProvider = ({ children }) => {
  var db = getFirestore();

  //data in localhost to avoid signup/in on the refresh of page
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const navigate = useNavigate();

  const [timeActive, setTimeActive] = useState(false);

  useEffect(() => {
    if (user) {
      initialize();
    }
  }, []);

  const initialize = async () => {
    const userDocRef = doc(db, "Users", user.id);
    const userDocSnapshot = await getDoc(userDocRef);
    if (
      !deepEqual(
        JSON.parse(localStorage.getItem("user")),
        userDocSnapshot.data()
      )
    ) {
      setUser({
        ...userDocSnapshot.data(),
      });
    }
  };

  const googleSignin = async () => {
    const googleAuthProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleAuthProvider)
      .then(async (result) => {
        var user = result.user;

        const userDocRef = doc(db, "Users", user.uid);

        // Getting the userDocon the bases if ID
        const userDocSnapshot = await getDoc(userDocRef); //  By userDocSnapshot.data() we can access our stored values

        if (!userDocSnapshot.exists()) {
          alert("Not allowed");
        } else if (
          localStorage.getItem("user") !== null ||
          (localStorage.getItem("user") === undefined &&
            !deepEqual(
              JSON.parse(localStorage.getItem("user")),
              userDocSnapshot.data()
            ) &&
            userDocSnapshot.data().isAdmin)
        ) {
          localStorage.removeItem("user");
          setUser({
            ...userDocSnapshot.data(),
          });
          navigate("/");
        } else if (userDocSnapshot.data().isAdmin) {
          setUser({
            ...userDocSnapshot.data(),
          });
          navigate("/");
        } else {
          alert("You donot pass the criteria to access this");
        }
      })
      .catch((error) => {
        console.log("error=" + error);
      });
  };

  const logout = () => {
    localStorage.removeItem("user");
  };

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  return (
    <UserAuthContext.Provider
      value={{
        user,
        timeActive,
        logout,
        setTimeActive,
        googleSignin,
      }}
    >
      {children}
    </UserAuthContext.Provider>
  );
};

//custome useContext hook
export const useUserAuthContext = () => {
  return useContext(UserAuthContext);
};
