import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  tableCellClasses,
} from "@mui/material";
import styled from "@emotion/styled";

const apiUrl = process.env.REACT_APP_BASE_URL;
console.log("apiurl:", apiUrl);

const ETFInfo = ({ ticker }) => {
  const [etfData, setEtfData] = useState(null);

  useEffect(() => {
    // Fetch data from your Flask API
    axios
      .get(`${apiUrl}api/get_etf_info?symbol=${ticker}`)
      .then((response) => {
        setEtfData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching ETF data:", error);
      });
  }, [ticker]);

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#17273B",
      color: "#A5CFF2",
    },
  }));

  return (
    <div className="etfdata_container">
      {etfData ? (
        <div
          className="etfdataTable"
          container
          spacing={2}
        >
          {Array.from({ length: 3 }).map((_, index) => (
            <Grid item xl={4} key={index}>
              <TableContainer
                sx={{ maxHeight: 270, overflowX: "hidden" }}
                component={Paper}
              >
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        sx={{ fontWeight: "bold", fontSize: "1rem" }}
                      >
                        Category
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ fontWeight: "bold", fontSize: "1rem" }}
                      >
                        Value
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(etfData)
                      .slice(
                        Math.floor(
                          (index * Object.entries(etfData).length) / 3
                        ),
                        Math.floor(
                          ((index + 1) * Object.entries(etfData).length) / 3
                        )
                      )
                      .map(([key, value]) => (
                        <TableRow key={key}>
                          <TableCell>{key}</TableCell>
                          <TableCell>{value}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ))}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default ETFInfo;
