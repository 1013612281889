import React, { useEffect } from "react";
import { useUserAuthContext } from "../providers/UserContext";
import { useNavigate } from "react-router";

export const LoginPage = () => {
  const { googleSignin, user } = useUserAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (user !== null) navigate("/");
  }, [user]);

  return (
    <div
      style={{
        boxShadow: `rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px`,
        padding: "2rem",
        width: "30%",
        minWidth: "300px",
        marginTop: "25%",
        margin: "20% auto 0 auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h5
        style={{
          color: "#344559",
          fontSize: "1.25rem",
          margin: "4px 0",
          textAlign: "center",
          textDecoration: "underline",
        }}
      >
        Sign in to App
      </h5>
      <br />
      <button className={`fetch_button`} onClick={googleSignin}>
        Login with Google
      </button>
    </div>
  );
};
