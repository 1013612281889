import React from "react";

const Disclaimer = () => {
  return (
    <div
      style={{
        boxShadow: `rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px`,
        padding: "1rem",
        marginBottom: "6rem",
      }}
    >
      <h5
        style={{
          color: "#344559",
          fontSize: "1.25rem",
          margin: "4px 0",
          textAlign: "center",
          textDecoration: "underline",
        }}
      >
        Financial Data Disclaimer
      </h5>
      <br />
      <p style={{ margin: "0 0", lineHeight: "1.2" }}>
        Please be advised that the financial data provided through this
        application could be inaccurate or incomplete. We rely on the yFinance
        API as of September 2023 but may change the data source at our
        discretion.
      </p>
      <br />
      <p style={{ margin: "0 0", lineHeight: "1.2" }}>
        We make no warranties or representations, express or implied, as to the
        accuracy, completeness, or fitness for any purpose or use of the data
        provided. We shall not be liable for any claims or losses of any nature
        arising directly or indirectly from the use of the data.
      </p>
      <br />
      <p style={{ margin: "0 0", lineHeight: "1.2" }}>
        You are solely responsible for verifying the data before relying on it.
        Use of the data is at your own risk, and we deny all liability for any
        actions taken based on this data.
      </p>
    </div>
  );
};

export default Disclaimer;
