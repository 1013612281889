import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./utils.css"; // Import your custom CSS file for additional styling
import { DateRange } from "@mui/icons-material/";
import { Button } from "@mui/material";

function DateRangePicker() {
  const initialStartDate = new Date("2023-08-14");
  const [dateRange, setDateRange] = useState({
    startDate: initialStartDate,
    endDate: new Date(),
  });

  return (
    <div
      className="date-range-picker-container"
      style={{ flexDirection: "column", gap: "0.5rem" }}
    >
      <div className="date-range-picker-container">
        <div className="date-range-picker-container" style={{ gap: "0.5rem" }}>
          <strong className="date-picker-label">From:</strong>
          <DatePicker
            showIcon
            icon={<DateRange />}
            minDate={dateRange.startDate}
            maxDate={new Date()}
            selected={dateRange.startDate}
            onChange={(date) =>
              setDateRange((prevDateRange) => ({
                ...prevDateRange,
                startDate: date,
              }))
            }
          />
        </div>
        <div className="date-range-picker-container" style={{ gap: "0.5rem" }}>
          <strong className="date-picker-label">To:</strong>
          <DatePicker
            showIcon
            icon={<DateRange />}
            minDate={dateRange.startDate}
            maxDate={new Date()}
            selected={dateRange.endDate}
            onChange={(date) =>
              setDateRange((prevDateRange) => ({
                ...prevDateRange,
                endDate: date,
              }))
            }
          />
        </div>
      </div>
      <Button variant="outlined" size="small" onClick={() => alert("Clear")}>
        Clear Filter
      </Button>
    </div>
  );
}

export default DateRangePicker;
