import "chart.js/auto";
import "chartjs-adapter-date-fns";
import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Tooltip,
  IconButton,
  Button,
} from "@mui/material";
import { Line } from "react-chartjs-2";
import Slider from "@mui/material/Slider";
import CircularProgress from "@mui/material/CircularProgress";
import "../App.css";
import KeyStat from "../KeyStat";
import MultiLevelDropdown from "../MultiLevelDropdown";
import EtfTable from "../EtfTable";
import { useUserAuthContext } from "../providers/UserContext";
import { useNavigate } from "react-router";
import { Lock, LockOpen } from "@mui/icons-material/";
import Disclaimer from "../components/Disclaimer";
import DateRangePicker from "../utils/DateRangePicker";

const Backtest = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const { user } = useUserAuthContext();
  const navigate = useNavigate();
  const [tickers, setTickers] = useState({});
  const [selectedTicker, setSelectedTicker] = useState(""); // Make sure this is an initial value that exists in the 'tickers' array
  const [timeWindow, setTimeWindow] = useState("1y"); // "1y" seems to be a valid initial value based on your MenuItem values
  const [stockPerformance, setStockPerformance] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [portfolio, setPortfolio] = useState([]);
  const [portfolioPerformance, setPortfolioPerformance] = useState(null);
  const [portfolioChartData, setPortfolioChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // used inside MenuItem to show circular loader
  const resultSectionRef = useRef(null); //Used to scroll to this section as res cones
  const [selectedCategory, setSelectedCategory] = useState("");
  const [loading, setLoading] = useState(false); //For stock loading button
  const [portfolioLoading, setPortfolioLoading] = useState(false); //For finantial loading button
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: "",
    endDate: "", // Initial date range
  });
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateFilteredData, setDateFilteredData] = useState(chartData);

  useEffect(() => {
    if (user === null) {
      navigate("/login");
    }
  }, [user]);
  const [portfolioWeight, setPortfolioWeight] = useState(0);

  const handleSelectionChange = (category, ticker) => {
    setSelectedCategory(category);
    setSelectedTicker(ticker);
  };

  const chartOptions = {
    scales: {
      x: {
        type: "time",
        time: {
          parser: "yyyy-MM-dd", // specify the format if necessary
        },
        title: "Temperature (in °C)",
        suffix: " °C",
      },
      y: {
        type: "linear",
        title: "Temperature (in °C)",
        suffix: " °C",
      },
    },
    elements: {
      point: {
        backgroundColor: "#B7E4E7", //on hover tooltip
      },
      line: {
        // backgroundColor: "", //under the graph line color
        // stepped: true,
        borderDash: [0],
        // fill: true,
        cubicInterpolationMode: "monotone", //no sharp edges
      },
    },

    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Performance Line Chart",
      },
    },
    pointStyle: false, //hide the points
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${apiUrl}api/get_ticker_info?ticker=${selectedTicker}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        setData(jsonData);
        setError(null);
      } catch (error) {
        setError(error.message || "An error occurred");
        setData({});
      }
    };
    // Fetch data when the component mounts or when the ticker state changes
    fetchData();
  }, [selectedTicker]);

  const getTextColorStyle = (value) => {
    return value >= 0 ? { color: "green" } : { color: "red" };
  };

  const fetchTickers = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${apiUrl}/api/available_tickers`);
      if (response.ok) {
        const data = await response.json();

        const convertedData = { tickers: {} };

        for (const category in data.tickers) {
          const categoryTickers = data.tickers[category];
          for (const ticker in categoryTickers) {
            convertedData.tickers[ticker] = categoryTickers[ticker];
          }
        }

        setTickers(convertedData.tickers);
      } else {
        console.error("Failed to fetch tickers:", response.status);
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching tickers:", error);
      setIsLoading(false);
    }
  };

  const fetchStockPerformance = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${apiUrl}api/backtest_stock?ticker=${selectedTicker}&window=${timeWindow}`
      );
      if (response.ok) {
        const data = await response.json();
        setStockPerformance(data?.stock_performance ?? null);
        // Prepare data for the chart
        const chartData = {
          labels: data.data.map((d) => d.Date),
          datasets: [
            {
              label: "Adjusted Close Price",
              data: data.data.map((d) => d["Adj Close"]),
              borderColor: "green", //border of the graph
              borderWidth: 2,
              // fill: "origin",
            },
          ],
        };
        setChartData(chartData);
        setDateFilteredData(chartData);
        setSelectedDateRange({
          startDate: chartData.labels[0],
          endDate: chartData.labels[chartData.labels.length - 1],
        });
        setLoading(false);
      } else {
        setLoading(false);
        console.error(`Server responded with ${response.status}`);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching stock performance:", error);
    }
  };

  useEffect(() => {
    // Filter the data based on the selected date range
    if (chartData != null) {
      const dateFilteredLabels = chartData?.labels.filter(
        (date) =>
          date >= selectedDateRange.startDate &&
          date <= selectedDateRange.endDate
      );
      const dateFilteredData = chartData?.datasets[0].data.slice(
        chartData?.labels.indexOf(dateFilteredLabels[0]),
        chartData?.labels.indexOf(
          dateFilteredLabels[dateFilteredLabels.length - 1]
        ) + 1
      );

      const updatedChartData = {
        ...chartData,
        labels: dateFilteredLabels,
        datasets: [
          {
            ...chartData.datasets[0],
            data: dateFilteredData,
          },
        ],
      };

      setDateFilteredData(updatedChartData);
    }
  }, [selectedDateRange]);

  const addTickerToPortfolio = (ticker) => {
    const name = tickers[ticker];
    setPortfolio([...portfolio, { ticker, name, weight: 0.2, locked: false }]);
  };

  useEffect(() => {
    const totalWeight = portfolio.reduce((sum, p) => sum + p.weight, 0);
    setPortfolioWeight(totalWeight);
  }, [portfolio]);

  const fetchPortfolioPerformance = async () => {
    setPortfolioLoading(true);
    try {
      const weights = portfolio.map((p) => p.weight);
      const tickers = portfolio.map((p) => p.ticker);

      // Ensure that the object you pass to JSON.stringify is serializable
      const payload = {
        tickers,
        weights,
        window: timeWindow, // Note: Renamed from 'window' to avoid confusion with the global 'window' object
      };

      const response = await fetch(`${apiUrl}api/backtest_portfolio`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        // console.log("🚀 ~ file: Backtest.jsx:221 ~ fetchPortfolioPerformance ~ data:", data, "and payload is : ", payload)
        setPortfolioPerformance(data?.portfolio_performance ?? null);

        // Parse the portfolio_performance_time_series string to JSON object
        const parsedTimeSeries = JSON.parse(
          data.portfolio_performance_time_series
        );

        const chartData = {
          labels: Object.keys(parsedTimeSeries),
          datasets: [
            {
              label: "Portfolio Performance",
              data: Object.values(parsedTimeSeries),
              borderColor: "green",
              borderWidth: 2,
              // fill: "origin",
            },
          ],
        };
        setPortfolioChartData(chartData);
        setPortfolioLoading(false);
      } else {
        setPortfolioLoading(false);

        console.error(`Server responded with ${response.status}`);
      }
    } catch (error) {
      setPortfolioLoading(false);

      console.error("Error fetching portfolio performance:", error);
    }
  };

  const removeTickerFromPortfolio = (tickerToRemove) => {
    setPortfolio(portfolio.filter((p) => p.ticker !== tickerToRemove));
  };

  const handleSliderChange = (e, newValue, indexToUpdate) => {
    // First, find the sum of all weights other than the one being updated
    let sumOfOtherWeights = 0;
    for (let i = 0; i < portfolio.length; i++) {
      if (i !== indexToUpdate) {
        sumOfOtherWeights += portfolio[i].weight;
      }
    }
    sumOfOtherWeights = parseFloat(sumOfOtherWeights.toFixed(2));

    // Then, find the remaining weight after the updated weight is subtracted from 1
    const remainingWeight = 1 - newValue;
    var remainingFixed = parseFloat(remainingWeight.toFixed(2));
    var fixedValue = parseFloat(newValue.toFixed(2)); //Upto 2 decimal
    const newPortfolio = portfolio.map((item, index) => {
      if (index === indexToUpdate) {
        return { ...item, weight: fixedValue };
      }

      // Reallocate the remaining weight among the other items,
      // preserving their relative ratios.
      if (!item.locked) {
        const newWeight = (item.weight / sumOfOtherWeights) * remainingFixed;
        var newFixed = parseFloat(newWeight.toFixed(2));
        return { ...item, weight: newFixed };
      }

      return item; // If locked, keep the weight unchanged.
    });

    setPortfolio(newPortfolio);
  };

  //to toggle the locked property of the clicked slider
  const toggleLock = (indexToUpdate) => {
    const newPortfolio = portfolio.map((item, index) => {
      if (index === indexToUpdate) {
        return { ...item, locked: !item.locked };
      }
      return item;
    });
    setPortfolio(newPortfolio);
  };

  useEffect(() => {
    fetchTickers();
  }, []);

  return (
    <Container>
      <h3
        style={{
          color: "#344559",
          textAlign: "center",
          fontSize: "2rem",
          fontWeight: "700",
          marginBottom: "1rem",
        }}
      >
        Investment Backtesting
      </h3>
      <MultiLevelDropdown onSelectionChange={handleSelectionChange} />
      <FormControl
        fullWidth
        variant="outlined"
        style={{ marginBottom: "20px" }}
      >
        <InputLabel>Time Window</InputLabel>
        <Select
          value={timeWindow}
          onChange={(e) => setTimeWindow(e.target.value)}
          label="Time Window"
        >
          <MenuItem value="1y">1 Year</MenuItem>
          <MenuItem value="3y">3 Years</MenuItem>
          <MenuItem value="5y">5 Years</MenuItem>
        </Select>
      </FormControl>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "2rem",
        }}
      >
        <button
          disabled={selectedTicker === "" || loading}
          onClick={fetchStockPerformance}
          className={`fetch_button ${
            selectedTicker === "" || loading ? "disabled" : ""
          }`}
        >
          {loading ? "Fetching Results ..." : "Fetch Stock Performance"}
        </button>
      </div>
      {data.ticker && stockPerformance && (
        <Divider style={{ marginTop: "40px", marginBottom: "20px" }} />
      )}

      {data.ticker && stockPerformance && (
        <div ref={resultSectionRef} id="resultSection">
          <div className="tickerinfo">
            <div className="tickername">
              <h2>{selectedTicker}</h2>
            </div>

            <div className="tickerdetails">
              <div className="price gen">
                <p className="tickersmallhead">Price</p>
                <p className="tickerdata">{data.price.toFixed(2)}</p>
              </div>
              <div className="todaychange gen">
                <p className="tickersmallhead">Change today</p>
                <p
                  className="tickerdata "
                  style={getTextColorStyle(data.percent_change)}
                >
                  {data.percent_change.toFixed(2)}%
                </p>
              </div>
              <div className="oneyearchange gen">
                <p className="tickersmallhead">shares Traded</p>
                <p className="tickerdata">{data.shares_traded.toFixed(2)}</p>
              </div>
              <div className="sharestraded gen">
                <p className="tickersmallhead">1 year change</p>
                <p
                  className="tickerdata"
                  style={getTextColorStyle(data.one_year_change)}
                >
                  {" "}
                  {data.one_year_change.toFixed(2)}
                </p>
              </div>
            </div>
          </div>

          {selectedCategory === "STOCKS" ? (
            <KeyStat ticker={selectedTicker} />
          ) : (
            <EtfTable ticker={selectedTicker} />
          )}
        </div>
      )}
      {stockPerformance !== null && (
        <div style={{ marginTop: "20px" }}>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h4
                style={{
                  color: "#344559",
                  fontSize: "1.75rem",
                  fontWeight: "600",
                  marginBottom: "12px",
                }}
              >
                Stock Performance: <strong>{stockPerformance}%</strong>
              </h4>
              {showDatePicker ? (
                <DateRangePicker />
              ) : (
                <Button
                  onClick={() => setShowDatePicker(false)}
                  variant="outlined"
                  color="success"
                >
                  Analyze by Date
                </Button>
              )}
            </div>

            {/* Stock Performance Data (based on dateRange) */}
            {chartData && (
              <Line
                data={dateFilteredData}
                options={chartOptions}
                width={400}
                height={200}
                style={{
                  padding: "1rem",
                  boxShadow: `rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px`,
                  borderRadius: "8px",
                }}
              />
            )}
          </div>
        </div>
      )}
      <Divider style={{ marginTop: "4rem", marginBottom: "20px" }} />
      <h3
        style={{
          color: "#344559",
          textAlign: "center",
          fontSize: "2rem",
          fontWeight: "700",
          marginBottom: "1rem",
        }}
      >
        Portfolio Backtesting
      </h3>
      <FormControl
        fullWidth
        variant="outlined"
        style={{ marginBottom: "20px" }}
      >
        <InputLabel>Add Ticker to Portfolio</InputLabel>
        <Select
          label="Add Ticker to Portfolio"
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200, // Set the maximum height for the menu
                overflowY: "auto", // Enable vertical scrolling
              },
            },
          }}
          onChange={(e) => addTickerToPortfolio(e.target.value)}
        >
          {isLoading ? (
            <MenuItem
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
              disabled
            >
              <CircularProgress
                style={{ color: "#344559" }}
                value={25}
                thickness={6}
                // disableShrink = {true}
              />
              <p className="waiting_text">
                This may take 5-10 seconds depending on your internet speed
              </p>
            </MenuItem>
          ) : (
            Object.keys(tickers).map((ticker, index) => (
              <MenuItem key={index} value={ticker}>
                {ticker} - {tickers[ticker]}
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>

      <div>
        {portfolio.map((p, index) => (
          <div key={index} style={{ marginBottom: "2rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h4 style={{ fontSize: "1.25rem", margin: "0 0" }}>
                {p.ticker} - {p.name} Weight
              </h4>
              <IconButton onClick={() => toggleLock(index)}>
                {p.locked ? <Lock /> : <LockOpen />}
              </IconButton>
            </div>
            <Slider
              value={p.weight.toFixed(2)}
              onChange={(e, newValue) => handleSliderChange(e, newValue, index)}
              step={0.01}
              min={0.01}
              marks
              max={1}
              valueLabelDisplay="on"
              sx={{ color: "#344559", height: "8px" }}
            />
            <button
              className="fetch_button"
              onClick={() => removeTickerFromPortfolio(p.ticker)}
            >
              Remove
            </button>
          </div>
        ))}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "2rem",
        }}
      >
        <Tooltip
          title={
            portfolioWeight < 1 ? "The sum of portfolio weights must be 1" : ""
          }
          placement="top"
          arrow
        >
          <button
            disabled={portfolioWeight < 1 || portfolioLoading}
            className={`fetch_button ${
              portfolioWeight !== 1 || portfolioLoading ? "disabled" : ""
            }`}
            onClick={fetchPortfolioPerformance}
          >
            {portfolioLoading
              ? "Fetching Results..."
              : "Fetch Portfolio Performance"}
          </button>
        </Tooltip>
      </div>

      {portfolioPerformance !== null && (
        <div style={{ marginTop: "20px" }}>
          <div>
            <h4
              style={{
                color: "#344559",
                textAlign: "left",
                fontSize: "1.75rem",
                fontWeight: "600",
                marginBottom: "12px",
              }}
            >
              Portfolio Performance: {portfolioPerformance}%
            </h4>
            {portfolioChartData && (
              <Line
                data={portfolioChartData}
                options={chartOptions}
                width={400}
                height={200}
                style={{
                  padding: "1rem",
                  boxShadow: `rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px`,
                  borderRadius: "8px",
                }}
              />
            )}
          </div>
        </div>
      )}
      <Divider style={{ marginTop: "4rem", marginBottom: "20px" }} />
      <Disclaimer />
    </Container>
  );
};

export default Backtest;
